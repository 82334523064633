import { NgModule } from '@angular/core';
import { HomeComponent } from '@app/home/home.component';
import { LandingPageModule } from '@app/landing-page/landing-page.module';
import { TransactionCalculatorModule } from '@app/transactions/components/transaction-calculator/transaction-calculator.module';
import { TransactionDataModule } from '@app/transactions/pages/transaction-data/transaction-data.module';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes, UrlSegment, UrlMatchResult } from '@angular/router';
import { RouteHandlerComponent } from '@app/core/components/route-handler/route-handler.component';
import { BestRatesModule } from '../landing-page/components/best-rates/best-rates.module';
import { CoreModule } from '@app/core/core.module';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { FeeMessageModule } from '@app/transactions/components/fee-message/fee-message.module';
import { TranslateModule } from '@ngx-translate/core';
import { supportedLenguage } from '@app/core/helpers/lenguages.helper';
import { AnimatedBlurredCirclesModule } from '@app/core/components/animated-blurred-circles/animated-blurred-circles.module';

const routes: Routes = [
    {
        // path: `/[|a|b]/system1/system2/amount1/amount2
        matcher: (url: any): UrlMatchResult | null => {
            let posParams: any = {};

            if (url.length === 0) {
                return {
                    consumed: url
                };
            }

            switch (true) {
                case ['a', 'b'].includes(url[0].path):
                    posParams = {
                        ...posParams,
                        step: new UrlSegment(url[0]?.path === 'b' ? 'b' : 'a', {})
                    };
                    break;
                case supportedLenguage.includes(url[0].path):
                    if (url.length > 1 && url[1].path !== '' && !['a', 'b'].includes(url[1].path)) {
                        return null;
                    }

                    posParams = {
                        ...posParams,
                        lenguageCode: new UrlSegment(url[0]?.path, {})
                    };
                    if (['a', 'b'].includes(url[1]?.path)) {
                        posParams = {
                            ...posParams,
                            step: new UrlSegment(url[1]?.path === 'b' ? 'b' : 'a', {})
                        };
                    }
                    break;
                default:
                    return null;
            }

            if (['a', 'b'].includes(url[0].path)) {
                posParams = {
                    ...posParams,
                    step: new UrlSegment(url[0]?.path === 'b' ? 'b' : 'a', {})
                };
            }

            return {
                consumed: url,
                posParams: {
                    ...posParams,
                    system1: new UrlSegment(url[1]?.path, {}),
                    system2: new UrlSegment(url[2]?.path, {}),
                    amount1: new UrlSegment(url[3]?.path, {}),
                    amount2: new UrlSegment(url[4]?.path, {})
                }
            };
        },
        component: HomeComponent,
        runGuardsAndResolvers: 'always'
    },
    {
        path: 'a/:system1/:system2/:amount1/:amount2/:refId',
        component: RouteHandlerComponent,
        runGuardsAndResolvers: 'always'
    },
    {
        path: 'b/:system1/:system2/:amount1/:amount2/:refId',
        component: RouteHandlerComponent,
        runGuardsAndResolvers: 'always'
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class HomeRoutingModule {}

@NgModule({
    declarations: [HomeComponent],
    exports: [HomeComponent],
    imports: [
        HomeRoutingModule,
        LandingPageModule,
        TransactionCalculatorModule,
        TransactionDataModule,
        AnimatedBlurredCirclesModule,
        CoreModule,
        MatCardModule,
        MatIconModule,
        MatButtonModule,
        BestRatesModule,
        FeeMessageModule,
        MatSlideToggleModule,
        TranslateModule,
        CommonModule
    ]
})
export class HomeModule {}
