import { Injectable } from '@angular/core';
import { Crisp } from 'crisp-sdk-web';
import { environment } from 'environments/environment.base';
import { User } from '../resources/user';
import { ServiceLoader } from './service-loader';
import { Observable, filter, map, switchMap } from 'rxjs';
import { UsersService } from './users.service';
import { TokenUpdateService } from '@app/dashboard/services/token-update.service';
import { ActivatedRoute } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class CrispService {
    private isCrispInitialized: boolean = false;
    private isTokenInitialized: boolean = false;

    public constructor(private tokenUpdateService: TokenUpdateService, private route: ActivatedRoute) {}

    public initializeCrisp(): void {
        if (!this.isCrispInitialized) {
            this.setConfigCrisp();
            this.isCrispInitialized = true;
        }
        this.checkUrlForCrisp();
    }

    private setConfigCrisp(): void {
        Crisp.configure(environment.WEBSITE_CRISP_ID, {
            autoload: false,
            sessionMerge: true,
            safeMode: true
        });
        this.loadCrisp();
    }

    public loadCrisp(): void {
        Crisp.load();
    }

    public logOutCrisp(): void {
        Crisp.setTokenId();
        Crisp.session.reset();
        this.loadCrisp();
    }

    public hideCrispChat(): void {
        Crisp.chat.hide();
    }

    public checkUrlForCrisp(): void {
        this.route.queryParams.subscribe((params: any) => {
            if (params.isOpenCrisp === 'true') {
                this.openCrispChat();
            }
        });
    }

    public openCrispChat(): void {
        Crisp.chat.open();
    }

    private setUserInfo(user: User): void {
        Crisp.user.setEmail(user.attributes.email);
        Crisp.user.setNickname(user.attributes.name);
    }

    public loadCrispOnLoggedUser(): void {
        this.extractTokenId().subscribe((tokenId: string) => {
            if (!tokenId || this.isTokenInitialized) {
                return;
            }
            this.isTokenInitialized = true;
            Crisp.setTokenId(tokenId);
            Crisp.chat.show();
        });
    }

    private extractTokenId(): Observable<string> {
        return this.tokenUpdateService.tokenUpdated$.pipe(
            switchMap((userId: string) => {
                if (!userId) {
                    return '';
                }
                let usersService: UsersService = ServiceLoader.injector.get(UsersService);

                return usersService.get(userId).pipe(
                    filter((user: any) => user.source !== 'new'),
                    map((user: User): string => {
                        if (user.attributes.has_previous_transactions !== false || !user.attributes.user_token_id) {
                            this.hideCrispChat();

                            return '';
                        }
                        this.setUserInfo(user);

                        return user.attributes.user_token_id;
                    })
                );
            })
        );
    }
}
