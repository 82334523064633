import { Component, ChangeDetectionStrategy, Input, ChangeDetectorRef } from '@angular/core';
import { environment } from 'environments/environment';
import { GlobalStateService } from '@app/core/services/global-state.service';
import { userLinks } from '../header/header.component';
import { UserCountryService } from '@app/core/services/user-country.service';
import { CountryProvider } from '@app/core/country-provider/country-provider';
import { IBankRepository } from '@app/core/country-provider/repositories/bank-repository-interface';
import { HeaderCountryService } from '../header/header-country.service';

@Component({
    selector: 'app-footer',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
    @Input() public type: string = '';
    private countryProvider: CountryProvider = new CountryProvider();
    public selectedCountry: { [key: string]: string } = {
        group_id: 'ars_bank',
        alt: 'Argentina'
    };
    public legacyUrl: string = environment.LEGACYURL + 'user/iniciar/';
    public helpLinks: Array<{ [key: string]: string }> = [
        {
            title: 'bottom_page_support_center_button',
            link: '/ayuda'
        },
        {
            title: 'bottom_page_support_frequent_questions_button',
            link: '/ayuda',
            fragment: 'faq'
        },
        {
            title: 'bottom_page_support_about_us_button',
            link: '/wiki/about-us'
        },
        {
            title: 'bottom_page_support_associates_program',
            link: '/wiki/referral-program'
        }
    ];
    public legalLinks: Array<{ [key: string]: string }> = [
        {
            title: 'bottom_page_legal_warranty_button',
            link: '/wiki/warranty'
        },
        {
            title: 'bottom_page_legal_terms_of_use_button',
            link: '/wiki/terms'
        },
        {
            title: 'bottom_page_legal_privacy_policy_button',
            link: '/wiki/privacy-policy'
        }
    ];
    public myAccountLinks: Array<{ [key: string]: any }> = userLinks;
    public icons_social: Array<{ [property: string]: string }> = [
        {
            netName: 'Saldo en Facebook',
            url: 'https://www.facebook.com/saldo.ar/',
            position: '0px'
        },
        {
            netName: 'Saldo en Instagram',
            url: 'https://www.instagram.com/saldo.ar/',
            position: '-30px'
        },
        {
            netName: 'Saldo en YouTube',
            url: 'https://www.youtube.com/channel/UCumZQa3vAgR1wG2bDKC8aUw',
            position: '-120px'
        },
        {
            netName: 'Saldo en Twitter',
            url: 'https://twitter.com/SaldoComAr',
            position: '-60px'
        },
        {
            netName: 'Blog de Saldoar',
            url: 'https://blog.saldo.com.ar/',
            position: '-90px'
        }
    ];

    public constructor(
        public globalStateService: GlobalStateService,
        private userCountryService: UserCountryService,
        private headerService: HeaderCountryService,
        private changeDetectorRef: ChangeDetectorRef
    ) {
        const countryHandler: (country: any) => void = (country: any): void => {
            this.setCountry(this.countryProvider.getCountry(country.code));
        };

        this.userCountryService.getCountry().subscribe(countryHandler);
        this.headerService.selectedCountry$.subscribe(countryHandler);
    }

    private setCountry(country: IBankRepository): void {
        let selectedCountry: { [key: string]: string } = {
            group_id: country.groupId,
            alt: country.countryName,
            positionSprite: country.widthPositionSprite + ' ' + country.heightPositionSprite,
            title: country.title,
            langCode: country.langCode,
            urlHref: country.urlHref
        };

        this.selectedCountry = selectedCountry;
        this.changeDetectorRef.markForCheck();
    }

    public linkWithLang(link: string): string {
        let redirectToDashboard: boolean = link.includes('/my/');
        let langCode: string = this.selectedCountry.langCode && !redirectToDashboard ? '/' + this.selectedCountry.langCode : '';

        return langCode + link;
    }
}
