export const about_us_es_AR: any = {
    title: 'Saldoar es confiable y estamos aquí para que intercambies tu dinero digital de manera segura',
    subtitle_one: 'Nuestra misión y valores',
    subtitle_two:
        'Saldo nació de una simple necesidad, intercambiar saldo y que cada persona que lo utiliza reciba lo pactado, acompañándolo en todo el proceso . Pronto su crecimiento fue exponencial debido a la confiabilidad, seguridad y velocidad en cada operación. Somos una empresa en la que las personas usuarias confían plenamente, ya que la importancia de ser transparente hacia ellos, es uno de nuestros pilares fundamentales. Ayudamos a que cada persona consiga, lo que está buscando de una manera fácil y protegida.',

    symplicity_title: 'Simplicidad',
    symplicity_info: 'La facilidad de uso de nuestra plataforma, permite que cada usuario obtenga lo pactado sin inconvenientes.',

    transparency_title: 'Transparencia',
    transparency_info: 'La letra chica es la letra grande del servicio, priorizamos la claridad en todo momento.',

    customer_s_title: 'Calidez Humana',
    customer_s_info: 'Contamos con un conjunto de personas dispuestas a resolver cualquier duda que surja en el proceso.',

    innovation_title: 'Innovación',
    innovation_info: 'Buscamos solucionar los problemas de las personas implementando las últimas tecnologías y sistemas de pago.',

    our_team_title: 'Buscamos solucionar los problemas de las personas implementando las últimas tecnologías y sistemas de pago.',

    time_helping_people_banner_text: 'Llevamos más de 10 años ayudando a personas de todo Latinoamérica a intercambiar su dinero virtual.',
    time_helping_people_banner_button: 'Comenzar ahora',

    press_download_title: 'Descargables de prensa',
    press_download_subtitle_one: 'Logotipo',
    press_download_subtitle_two: 'Material Institucional',
    logo_file_one: 'Versión original',
    logo_file_two: 'Versión blanco',
    file_one: 'Manual de <br>marca',
    file_two: 'Gacetilla de <br>prensa',

    related_news_title: 'Noticias relacionadas',
    related_un_new_text: 'Saldoar: Así puedes cambiar tu saldo PayPal por bolívares.',
    related_forbes_new_text: 'Cómo exportar servicios y evitar la pesificación al oficial.',
    related_iproup_new_text: 'Crecen las compras de dólar blue desde los hogares.',
    related_tiempo_final_new_text: 'Análisis de una de las mejores opciones para retirar dinero del exterior.'
};
